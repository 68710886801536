import ReactDOM from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';

// style + assets
import 'assets/scss/style.scss';
import axios from 'axios';
// eslint-disable-next-line
import Symbol_observable from 'symbol-observable';

// ==============================|| REACT DOM RENDER  ||============================== //
axios.get(`${process.env.REACT_APP_BASE_URL}/sanctum/csrf-cookie`, { withCredentials: true });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
