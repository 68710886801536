import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import config from 'config';
import NotFound from 'views/pages/NotFound';
import Welcome from 'views/pages/Welcome';
import ClientRoutes from './ClientRoutes';
import NotAuthorized from 'views/pages/NotAuthorized';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes(
        [
            AuthenticationRoutes,
            ClientRoutes,
            MainRoutes,
            {
                path: '*',
                element: <NotFound />
            },
            {
                path: '/welcome',
                element: <Welcome />
            },
            {
                path: '/unauthorized',
                element: <NotAuthorized />
            }
        ],
        config.basename
    );
}
