// material-ui
import { styled } from '@mui/material/styles';

// ==============================|| AUTHENTICATION 1 WRAPPER ||============================== //

const AuthWrapper1 = styled('div')(() => ({
    backgroundColor: 'rgba(255,255,255,0.6)',
    minHeight: '100vh'
}));

export default AuthWrapper1;
