import { IconDashboard, IconReport, IconWriting } from '@tabler/icons-react';
import ListIcon from '@mui/icons-material/List';

const icons = { IconDashboard, IconReport, IconWriting, ListIcon };

export const utilities = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'utils',
            title: 'Utilities',
            type: 'collapse',
            icon: icons.ListIcon,

            children: [
                {
                    id: 'contracts',
                    title: 'Manage Contracts',
                    type: 'item',
                    url: '/client/contracts',
                    target: false
                },
                {
                    id: 'reports',
                    title: 'Manage Reports',
                    type: 'item',
                    url: '/client/reports',
                    target: false
                },
                {
                    id: 'contractors',
                    title: 'Contractors Database',
                    type: 'item',
                    url: '/client/contractors',
                    target: false
                },
                {
                    id: 'suppliers',
                    title: 'Suppliers Database',
                    type: 'item',
                    url: '/client/suppliers',
                    target: false
                },
                {
                    id: 'gallery',
                    title: 'Gallery',
                    type: 'item',
                    url: '/client/gallery',
                    target: false
                }
            ]
        }
    ]
};
