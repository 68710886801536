import cdsf from './cdsf';
import cp from './cp';
import rdf from './rdf';
import education from './education';
import { utilities } from './utitlities';
// import pages from './pages';
// import utilities from './utilities';
// import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const clientMenuItems = {
    // items: [dashboard, pages, utilities, other]
    items: [utilities, cdsf, education, cp, rdf]
};

export default clientMenuItems;
