// assets
import { IconBuilding, IconBuildingCommunity, IconWallet, IconTractor } from '@tabler/icons-react';

// constant
const icons = {
    IconBuilding,
    IconBuildingCommunity,
    IconWallet,
    IconTractor
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const rdf = {
    id: 'rdf',
    title: 'RDF',
    caption: 'Regional Development Fund',
    type: 'group',
    children: [
        {
            id: 'dashboard-rdf',
            title: 'Dashboard',
            type: 'item',
            url: '/client/dashboard/rdf',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'infrastructure-projects',
            title: 'Infrastructure Projects',
            type: 'collapse',
            icon: icons.IconBuilding,

            children: [
                {
                    id: 'infrastructure-education',
                    title: 'Education',
                    type: 'item',
                    url: '/client/rdf-education',
                    target: false
                },
                {
                    id: 'infrastructure-community-centres',
                    title: 'Community Centres',
                    type: 'item',
                    url: '/client/rdf-community-centres',
                    target: false
                },
                {
                    id: 'infrastructure-agriculture',
                    title: 'Agriculture',
                    type: 'item',
                    url: '/client/rdf-agriculture',
                    target: false
                },
                {
                    id: 'water',
                    title: 'Water & Sanitation',
                    type: 'item',
                    url: '/client/rdf-water',
                    target: false
                },
                {
                    id: 'infrastructure-rural-electrification',
                    title: 'Rural Electrification',
                    type: 'item',
                    url: '/client/rdf-rural-electrification',
                    target: false
                }
            ]
        },
        {
            id: 'incomeGenerating',
            title: 'Income Generating Projects',
            type: 'item',
            url: '/client/rdf-income-generating',
            icon: icons.IconWallet,
            breadcrumbs: false
        }
    ]
};

export default rdf;
