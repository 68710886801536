// assets
import { IconDashboard, IconReport, IconWriting } from '@tabler/icons-react';
import ListIcon from '@mui/icons-material/List';

// constant
const icons = { IconDashboard, IconReport, IconWriting, ListIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'reports',
            title: 'Reports',
            type: 'collapse',
            icon: icons.IconReport,

            children: [
                {
                    id: 'completed-projects',
                    title: 'Completed Projects',
                    type: 'item',
                    url: '/completed-projects',
                    target: false
                },
                {
                    id: 'ongoing-projects',
                    title: 'Ongoing Projects',
                    type: 'item',
                    url: '/ongoing-projects',
                    target: false
                },
                {
                    id: 'to-be-launched-projects',
                    title: 'To Be Launched Projects',
                    type: 'item',
                    url: '/to-be-launched-projects',
                    target: false
                },
                {
                    id: 'deferred-projects',
                    title: 'Deferred Projects',
                    type: 'item',
                    url: '/deferred-projects',
                    target: false
                },
                {
                    id: 'cancelled-projects',
                    title: 'Cancelled Projects',
                    type: 'item',
                    url: '/cancelled-projects',
                    target: false
                },
                {
                    id: 'dlp',
                    title: 'DLP',
                    type: 'item',
                    url: '/dlp-projects',
                    target: false
                },
                {
                    id: 'deleted-projects',
                    title: 'Deleted Projects',
                    type: 'item',
                    url: '/deleted-projects',
                    target: false
                },
                {
                    id: 'financial',
                    title: 'Financial',
                    type: 'item',
                    url: '/under-construction',
                    target: false
                }
            ]
        },
        {
            id: 'contracts',
            title: 'Contracts Awarded',
            type: 'collapse',
            icon: icons.IconWriting,

            children: [
                {
                    id: 'contracts-education',
                    title: 'Education (MOET)',
                    type: 'item',
                    url: '/under-construction',
                    target: false
                },
                {
                    id: 'contracts-capital-projects',
                    title: 'Capital Projects',
                    type: 'item',
                    url: '/under-construction',
                    target: false
                },
                {
                    id: 'contracts-health',
                    title: 'Health',
                    type: 'item',
                    url: '/under-construction',
                    target: false
                },
                {
                    id: 'contracts-other',
                    title: 'Other Ministries',
                    type: 'item',
                    url: '/under-construction',
                    target: false
                },
                {
                    id: 'contracts-rdf',
                    title: 'RDF',
                    type: 'item',
                    url: '/under-construction',
                    target: false
                }
            ]
        },
        {
            id: 'utils',
            title: 'Utilities',
            type: 'collapse',
            icon: icons.ListIcon,

            children: [
                {
                    id: 'funds',
                    title: 'Manage Funds',
                    type: 'item',
                    url: '/funds',
                    target: false
                },
                {
                    id: 'sectors',
                    title: 'Manage Sectors',
                    type: 'item',
                    url: '/sectors',
                    target: false
                },
                {
                    id: 'users',
                    title: 'Manage MPP Officers',
                    type: 'item',
                    url: '/users',
                    target: false
                },
                {
                    id: 'projects',
                    title: 'Manage Projects',
                    type: 'item',
                    url: '/projects',
                    target: false
                },
                {
                    id: 'contracts',
                    title: 'Manage Contracts',
                    type: 'item',
                    url: '/contracts',
                    target: false
                },
                {
                    id: 'reports',
                    title: 'Manage Reports',
                    type: 'item',
                    url: '/reports',
                    target: false
                },
                {
                    id: 'contractors',
                    title: 'Contractors Database',
                    type: 'item',
                    url: '/contractors',
                    target: false
                },
                {
                    id: 'suppliers',
                    title: 'Suppliers Database',
                    type: 'item',
                    url: '/suppliers',
                    target: false
                },
                {
                    id: 'gallery',
                    title: 'Gallery',
                    type: 'item',
                    url: '/gallery',
                    target: false
                }
            ]
        }
    ]
};

export default dashboard;
