import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
// import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';

// assets
import { IconChevronRight } from '@tabler/icons-react';
import { useStateContext } from 'context/ContextProvider';
import { onMessageListener, messaging } from 'ui-component/Firebase';
import { getToken } from 'firebase/messaging';
import { cleanup } from '@testing-library/react';
import addNotification from 'react-push-notification';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const ClientLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const { menu, token, SET_MENU, user, client } = useStateContext();

    const updateToken = async (token) => {
        await client.patch('/token', { fcm_token: token }).catch((error) => console.log(error));
    };

    const getDeviceToken = () =>
        Notification.requestPermission().then(async (permission) => {
            if (permission === 'granted') {
                return getToken(messaging, {
                    vapidKey: process.env.REACT_APP_PUBLIC_KEY
                })
                    .then((currentToken) => {
                        if (currentToken && user.fcm_token !== currentToken) {
                            updateToken(currentToken);
                            console.log(currentToken);
                        } else {
                            console.log('Failed to generate the app registration token.');
                        }
                    })
                    .catch((err) => {
                        console.log('An error occurred when requesting to receive the token.', err);
                    });
            }
            return token;
        });

    useEffect(() => {
        getDeviceToken();
        return () => {
            cleanup();
        };
    }, []);

    onMessageListener()
        .then((payload) => {
            const options={
                body:payload.data.body
                }
               const notification= new Notification(payload.data.title,options)
        })
        .catch((err) => console.log('failed: ', err));

    const handleLeftDrawerToggle = () => {
        SET_MENU(!menu);
    };

    useEffect(() => {
        SET_MENU(!matchDownMd);
        return () => {
            SET_MENU();
            cleanup();
        };
    }, [matchDownMd]);

    return (
        <Box sx={{ display: 'flex' }}>
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: menu ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar drawerOpen={menu} drawerToggle={handleLeftDrawerToggle} />

            {/* main content */}
            <Main theme={theme} open={menu}>
                {/* breadcrumb */}
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <Outlet />
            </Main>
            {/* <Customization /> */}
        </Box>
    );
};

export default ClientLayout;
