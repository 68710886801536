// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';

// import Logo from 'ui-component/Logo';
import BackgroundSlider from 'react-background-slider';
import AuthFooter from 'ui-component/cards/AuthFooter';

// assets
import logo from 'assets/images/logo1.png';
import AuthWrapper1 from './authentication/AuthWrapper1';
import { useStateContext } from 'context/ContextProvider';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { images } from 'assets/images';

const Welcome = () => {
    const theme = useTheme();
    const { client, user, setUser, setToken, setNotification } = useStateContext();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [isSubmitting, setSubmitting] = useState(false);
    const navigate = useNavigate();

    const handleLogout = (e) => {
        setSubmitting(true);
        e.preventDefault();
        client.post('/logout').then((res) => {
            if (res.status === 200) {
                localStorage.removeItem('ACCESS_TOKEN');
                setNotification('success', 'Success', res.data.message);
                setSubmitting(false);
                setToken(null);
                setUser({});
                navigate('/pages/login/login3');
            }
        });
    };

    return (
        <div>
            <AuthWrapper1>
                <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                            <Grid item xs={6} style={{ textAlign: 'center' }}>
                                <Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h2' : 'h1'}>
                                    Hi..., {`${user.fname} ${user.lname}`}
                                </Typography>
                                <Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h2' : 'h1'}>
                                    Welcome to Micro Projects eSwatini
                                </Typography>
                                <img src={logo} alt="logo" />
                                <Typography
                                    color={theme.palette.secondary.main}
                                    gutterBottom
                                    variant={matchDownSM ? 'h4' : 'h3'}
                                    style={{ fontStyle: 'italic' }}
                                >
                                    &quot;We Empower Rural Communities To Develop Themselves&quot;
                                </Typography>
                                <Box sx={{ mt: 2 }}>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            disabled={isSubmitting}
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleLogout}
                                        >
                                            Log Out
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                        <AuthFooter />
                    </Grid>
                </Grid>
            </AuthWrapper1>
            <BackgroundSlider images={images} duration={5} transition={2} />
        </div>
    );
};

export default Welcome;
