import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme } from '@mui/material/styles';
import { Formik } from 'formik';
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import useScriptRef from 'hooks/useScriptRef';
import { useStateContext } from 'context/ContextProvider';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import AnimateButton from 'ui-component/extended/AnimateButton';
import Swal from 'sweetalert2';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Microprojects eSwatini-
            </Link>
            {new Date().getFullYear()}
        </Typography>
    );
}

const AuthForgotPassword = () => {
    const theme = createTheme();
    const scriptedRef = useScriptRef();
    // const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const { setNotification, client } = useStateContext();
    // const googleHandler = async () => {
    //     console.error('Login');
    // };
    const navigate = useNavigate();
    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Formik
                    initialValues={{
                        email: '',
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email('Must be a valid email').max(255).required('Email is required')
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            if (scriptedRef.current) {
                                client
                                    .post('/forgot_password', values)
                                    .then((res) => {
                                        if (res.status === 200) {
                                            setNotification('success', 'Success', res.data.status);
                                            navigate('/pages/login/login3');
                                            setStatus({ success: true });
                                            setSubmitting(false);
                                            Swal.fire('Success', res.data.message, 'success');
                                        }
                                    })
                                    .catch((err) => {
                                        const response = err.response;
                                        if (response && response.status === 422) {
                                            if (response.data.errors) {
                                                setErrors(response.data.errors);
                                            } else {
                                                setErrors({
                                                    email: [response.data.message]
                                                });
                                            }
                                        }
                                    });
                            }
                        } catch (err) {
                            console.error(err);
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <FormControl
                                fullWidth
                                error={Boolean(touched.email && errors.email)}
                                sx={{ ...theme.typography.customInput, mb: 5 }}
                            >
                                <InputLabel htmlFor="outlined-adornment-email-login">Email Address</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-email-login"
                                    type="email"
                                    value={values.email}
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label="Email Address / Username"
                                    inputProps={{}}
                                />
                                {touched.email && errors.email && (
                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                        {errors.email}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <AnimateButton>
                                <Button
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleSubmit}
                                >
                                    Sign In
                                </Button>
                            </AnimateButton>
                        </Box>
                    )}
                </Formik>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
};

export default AuthForgotPassword;
