import dashboard from './dashboard';
import cdsf from './cdsf';
import cp from './cp';
import rdf from './rdf';
import education from './education';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, cdsf, education, cp, rdf]
};

export default menuItems;
