import { useNavigate } from 'react-router-dom';

const LoginRouteProtection = ({ children }) => {
    const token = localStorage.getItem('ACCESS_TOKEN');
    const navigate = useNavigate();

    if (token && token !== null) {
        navigate(-1);
        return null;
    }
    return children;
};

export default LoginRouteProtection;
