// material-ui
// import { useTheme } from '@mui/material/styles';

import { Box, Typography } from '@mui/material';
import logo from 'assets/images/logo1.png';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () =>  (

        <div style={{ display: 'flex' }}>
            <img src={logo} alt="logo" width={50} height={50} style={{ marginRight: '5px' }} />
            <div>
                <h3>Microprojects</h3>
                <Box>
                    <Typography variant="subtitle2" underline="hover">
                     {process.env.REACT_APP_VERSION}
                    </Typography>
                </Box>
            </div>
        </div>
    );


export default Logo;
