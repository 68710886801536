// assets
// import { IconBuildingFortress, IconBuildingBank } from '@tabler/icons';
// import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';

// constant
// const icons = {
//     IconBuildingFortress,
//     LocalPoliceOutlinedIcon,
//     IconBuildingBank
// };

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const education = {
    id: 'education-moet',
    title: 'EDUCATION',
    caption: 'MOET',
    type: 'group',
    children: [
        {
            id: 'dashboard-moet',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/moet',
            breadcrumbs: false
        },
        {
            id: 'education-class-rooms',
            title: 'Class Rooms',
            type: 'item',
            url: '/classrooms',
            target: false
        },
        {
            id: 'education-staff-houses',
            title: 'Staff Houses',
            type: 'item',
            url: '/staff-houses',
            target: false
        },
        {
            id: 'education-admin-blocks',
            title: 'Admin Blocks',
            type: 'item',
            url: '/admin-blocks',
            target: false
        },
        {
            id: 'education-libraries',
            title: 'Libraries',
            type: 'item',
            url: '/libraries',
            target: false
        },
        {
            id: 'education-kitchens',
            title: 'Kitchens',
            type: 'item',
            url: '/kitchens',
            target: false
        },
        {
            id: 'education-toilets',
            title: 'Toilets',
            type: 'item',
            url: '/toilets',
            target: false
        },
        {
            id: 'education-laboritoties',
            title: 'Laboritories',
            type: 'item',
            url: '/laboratories',
            target: false
        },
        {
            id: 'education-carpentry-fittings',
            title: 'Carpentry Fittings',
            type: 'item',
            url: '/carpentry-fittings',
            target: false
        },
        {
            id: 'education-fencing',
            title: 'Fencing',
            type: 'item',
            url: '/fencing',
            target: false
        },
        {
            id: 'education-rehab',
            title: 'Storm Damage & Rehabilitation',
            type: 'item',
            url: '/storm-damage-and-rehabilitation',
            target: false
        },
        {
            id: 'education-tertiary',
            title: 'Tertiary Institutions (Capital Projects)',
            type: 'item',
            url: '/tertiary-institution',
            target: false
        }
    ]
};

export default education;
