import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AdminProtectedRoutes from './AdminProtectedRoutes';
import { Navigate } from 'react-router-dom';

const Suppliers = Loadable(lazy(() => import('views/pages/reports/database/Suppliers')));
const Contractors = Loadable(lazy(() => import('views/pages/reports/database/Contractors')));
const CompletedProject = Loadable(lazy(() => import('views/pages/reports/project-report/CompletedProject')));
const WaitingProject = Loadable(lazy(() => import('views/pages/reports/project-report/WaitingProject')));
const DLP = Loadable(lazy(() => import('views/pages/reports/project-report/DLP')));
const DeletedProjects = Loadable(lazy(() => import('views/pages/reports/project-report/DeletedProjects')));
const CancelledProjects = Loadable(lazy(() => import('views/pages/reports/project-report/CancelledProjects')));
const DeferredProjects = Loadable(lazy(() => import('views/pages/reports/project-report/DeferredProjects')));
const OngoingProjects = Loadable(lazy(() => import('views/pages/reports/project-report/OngoingProjects')));
const  PrivateCabinet=Loadable(lazy(()=>import('views/pages/cp/various-other-ministries/PrivateCabinet')));

const Roads = Loadable(lazy(() => import('views/pages/cdsf/transport&comunication/roads')));
const Suspended = Loadable(lazy(() => import('views/pages/cdsf/transport&comunication/suspended-foot-bridge')));
const CommunityHalls = Loadable(lazy(() => import('views/pages/cdsf/community_centers/CommunityHalls')));
const MarketStalls = Loadable(lazy(() => import('views/pages/cdsf/community_centers/MarketStalls')));
const HealthWelfare=Loadable(lazy(()=>import('views/pages/cdsf/HealtWelfare')));

const DipTanks = Loadable(lazy(() => import('views/pages/cdsf/agriculture/DipTanks')));
const GrazingSchemes = Loadable(lazy(() => import('views/pages/cdsf/agriculture/GrazingSchemes')));
const VeterinaryOffices = Loadable(lazy(() => import('views/pages/cdsf/agriculture/VeterinaryOffices')));

const CommunityWaterSupply = Loadable(lazy(() => import('views/pages/cdsf/water&sanitation/CommunityWaterSupply')));
const HealthWaterSupply = Loadable(lazy(() => import('views/pages/cdsf/water&sanitation/HealthWaterSupply')));
const SchoolsWaterSupply = Loadable(lazy(() => import('views/pages/cdsf/water&sanitation/SchoolsWaterSupply')));

const Phase1 = Loadable(lazy(() => import('views/pages/cdsf/rural-electrification/Phase1')));
const Phase2 = Loadable(lazy(() => import('views/pages/cdsf/rural-electrification/Phase2')));
const Phase3 = Loadable(lazy(() => import('views/pages/cdsf/rural-electrification/Phase3')));
const Phase4 = Loadable(lazy(() => import('views/pages/cdsf/rural-electrification/Phase4')));
const Phase5 = Loadable(lazy(() => import('views/pages/cdsf/rural-electrification/Phase5')));

const Hhohho = Loadable(lazy(() => import('views/pages/cdsf/education-hhohho/Hhohho')));
const Lubombo = Loadable(lazy(() => import('views/pages/cdsf/education-hhohho/Lubombo')));
const Manzini = Loadable(lazy(() => import('views/pages/cdsf/education-hhohho/Manzini')));
const Shiselweni = Loadable(lazy(() => import('views/pages/cdsf/education-hhohho/Shiselweni')));

const AdminBlocks = Loadable(lazy(() => import('views/pages/education/AdminBlocks')));
const CarpentryFittings = Loadable(lazy(() => import('views/pages/education/CarpentryFittings')));
const ClassRooms = Loadable(lazy(() => import('views/pages/education/ClassRooms')));
const Fencing = Loadable(lazy(() => import('views/pages/education/Fencing')));
const Kitchens = Loadable(lazy(() => import('views/pages/education/Kitchens')));
const Laboratories = Loadable(lazy(() => import('views/pages/education/Laboratories')));
const Libraries = Loadable(lazy(() => import('views/pages/education/Libraries')));
const StaffHouses = Loadable(lazy(() => import('views/pages/education/StaffHouses')));
const StormDamageAndRehabilitation = Loadable(lazy(() => import('views/pages/education/StormDamageAndRehabilitation')));
const TertiaryInstitution = Loadable(lazy(() => import('views/pages/education/TertiaryInstitution')));
const Toilets = Loadable(lazy(() => import('views/pages/education/Toilets')));

const IncomeGenerating = Loadable(lazy(() => import('views/pages/rdf/income-generating-projects')));
const Project = Loadable(lazy(() => import('ui-component/project')));
const NewContract = Loadable(lazy(() => import('ui-component/contract')));
const RuralElectrification = Loadable(lazy(() => import('views/pages/rdf/infrastructure-projects/RuralElectrification')));
const RdfAgriculture = Loadable(lazy(() => import('views/pages/rdf/infrastructure-projects/RdfAgriculture')));
const Education = Loadable(lazy(() => import('views/pages/rdf/infrastructure-projects/Education')));
const CommunityCentres = Loadable(lazy(() => import('views/pages/rdf/infrastructure-projects/CommunityCentres')));

const SportsCultureAndYouth = Loadable(lazy(() => import('views/pages/cp/various-other-ministries/SportsCultureAndYouth')));
const ICT = Loadable(lazy(() => import('views/pages/cp/various-other-ministries/ICT')));
const Health = Loadable(lazy(() => import('views/pages/cp/various-other-ministries/Health')));
const Finance = Loadable(lazy(() => import('views/pages/cp/various-other-ministries/Finance')));
const EswatiniParliament = Loadable(lazy(() => import('views/pages/cp/various-other-ministries/EswatiniParliament')));
const EswatiniFireDepartment = Loadable(lazy(() => import('views/pages/cp/various-other-ministries/EswatiniFireDepartment')));
const DPM = Loadable(lazy(() => import('views/pages/cp/various-other-ministries/DPM')));
const Defence = Loadable(lazy(() => import('views/pages/cp/various-other-ministries/Defence')));
const CorrectionalService = Loadable(lazy(() => import('views/pages/cp/various-other-ministries/CorrectionalService')));
const Agriculture = Loadable(lazy(() => import('views/pages/cp/various-other-ministries/EswatiniFireDepartment')));

const ContractorDetails = Loadable(lazy(() => import('ui-component/supplier_contractor/ContractorDetails')));
const SupplierDetails = Loadable(lazy(() => import('ui-component/supplier_contractor/SupplierDetails')));

const Users = Loadable(lazy(() => import('views/pages/dashbord/utilities/Users')));
const Funds = Loadable(lazy(() => import('views/pages/dashbord/utilities/Funds')));
const Projects = Loadable(lazy(() => import('views/pages/dashbord/utilities/Projects')));
const Sectors = Loadable(lazy(() => import('views/pages/dashbord/utilities/Sectors')));
const ManageSectors = Loadable(lazy(() => import('ui-component/utils/ManageSectors')));
const ManageFunds = Loadable(lazy(() => import('ui-component/utils/ManageFunds')));
const AccountSettings = Loadable(lazy(() => import('ui-component/account-settings')));
const Reports = Loadable(lazy(() => import('views/pages/dashbord/utilities/Reports')));
const Contracts = Loadable(lazy(() => import('views/pages/dashbord/utilities/Contracts')));
const Gallery = Loadable(lazy(() => import('views/pages/dashbord/utilities/Gallery')));

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardCDSF = Loadable(lazy(() => import('views/dashboard/Default/indexCDSF')));
const DashboardMOET = Loadable(lazy(() => import('views/dashboard/Default/indexMOET')));
const DashboardCF = Loadable(lazy(() => import('views/dashboard/Default/indexCF')));
const DashboardRDF = Loadable(lazy(() => import('views/dashboard/Default/indexRDF')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const NotAuthorized = Loadable(lazy(() => import('views/pages/NotAuthorized')));
const UnderConstruction = Loadable(lazy(() => import('views/pages/under-construction')));
const FootBridge = Loadable(lazy(() => import('views/pages/cdsf/transport&comunication/foot-bridge')));
const LowLevelCrossing = Loadable(lazy(() => import('views/pages/cdsf/transport&comunication/low-level-crossing')));
const WaterProjects = Loadable(lazy(() =>import('views/pages/rdf/infrastructure-projects/WaterProjects')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AdminProtectedRoutes>
            <MainLayout />
        </AdminProtectedRoutes>
    ),
    children: [
        {
            path: '/',
            element: <Navigate to="/dashboard/default" replace />
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/cdsf',
            element: <DashboardCDSF />
        },
        {
            path: '/dashboard/moet',
            element: <DashboardMOET />
        },
        {
            path: '/dashboard/cf',
            element: <DashboardCF />
        },
        {
            path: '/dashboard/rdf',
            element: <DashboardRDF />
        },
        {
            path: '/utils/util-typography',
            element: <UtilsTypography />
        },
        {
            path: '/utils/util-color',
            element: <UtilsColor />
        },
        {
            path: '/utils/util-shadow',
            element: <UtilsShadow />
        },
        {
            path: '/icons/tabler-icons',
            element: <UtilsTablerIcons />
        },
        {
            path: '/icons/material-icons',
            element: <UtilsMaterialIcons />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/bridges/foot-bridges',
            element: <FootBridge />
        },
        {
            path: '/bridges/low-level-crossing',
            element: <LowLevelCrossing />
        },
        {
            path: '/roads',
            element: <Roads />
        },
        {
            path: '/suspended',
            element: <Suspended />
        },
        {
            path: '/under-construction',
            element: <UnderConstruction />
        },
        {
            path: '/completed-projects',
            element: <CompletedProject />
        },
        {
            path: '/dlp-projects',
            element: <DLP />
        },
        {
            path: '/cancelled-projects',
            element: <CancelledProjects />
        },
        {
            path: '/deferred-projects',
            element: <DeferredProjects />
        },
        {
            path: '/ongoing-projects',
            element: <OngoingProjects />
        },
        {
            path: '/to-be-launched-projects',
            element: <WaitingProject />
        },
        {
            path: '/deleted-projects',
            element: <DeletedProjects />
        },
        {
            path: '/contractors',
            element: <Contractors />
        },
        {
            path: '/contractor/:id',
            element: <ContractorDetails />
        },
        {
            path: '/new_contractor',
            element: <ContractorDetails />
        },
        {
            path: '/suppliers',
            element: <Suppliers />
        },
        {
            path: '/new_supplier',
            element: <SupplierDetails />
        },
        {
            path: '/supplier/:id',
            element: <SupplierDetails />
        },
        {
            path: '/community-halls',
            element: <CommunityHalls />
        },
        {
            path: '/market-stalls',
            element: <MarketStalls />
        },
        {
            path: '/dip-tanks',
            element: <DipTanks />
        },
        {
            path: '/veterinary-offices',
            element: <VeterinaryOffices />
        },
        {
            path: '/grazing-schemes',
            element: <GrazingSchemes />
        },
        {
            path: '/schools-water',
            element: <SchoolsWaterSupply />
        },
        {
            path: '/community-water',
            element: <CommunityWaterSupply />
        },
        {
            path: '/health-water',
            element: <HealthWaterSupply />
        },
        {
            path: '/phase-1',
            element: <Phase1 />
        },
        {
            path: '/phase-2',
            element: <Phase2 />
        },
        {
            path: '/phase-3',
            element: <Phase3 />
        },
        {
            path: '/phase-4',
            element: <Phase4 />
        },
        {
            path: '/phase-5',
            element: <Phase5 />
        },
        {
            path: '/hhohho',
            element: <Hhohho />
        },
        {
            path: '/manzini',
            element: <Manzini />
        },
        {
            path: '/shiselweni',
            element: <Shiselweni />
        },
        {
            path: '/lubombo',
            element: <Lubombo />
        },
        {
            path: '/fencing',
            element: <Fencing />
        },
        {
            path: '/libraries',
            element: <Libraries />
        },
        {
            path: '/laboratories',
            element: <Laboratories />
        },
        {
            path: '/kitchens',
            element: <Kitchens />
        },
        {
            path: '/storm-damage-and-rehabilitation',
            element: <StormDamageAndRehabilitation />
        },
        {
            path: '/carpentry-fittings',
            element: <CarpentryFittings />
        },
        {
            path: '/classrooms',
            element: <ClassRooms />
        },
        {
            path: '/staff-houses',
            element: <StaffHouses />
        },
        {
            path: '/private_cabinet',
            element: <PrivateCabinet/>
        },
        {
            path: '/admin-blocks',
            element: <AdminBlocks />
        },
        {
            path: '/tertiary-institution',
            element: <TertiaryInstitution />
        },
        {
            path: '/toilets',
            element: <Toilets />
        },
        {
            path: '/agriculture',
            element: <Agriculture />
        },
        {
            path: '/finance',
            element: <Finance />
        },
        {
            path: '/ict',
            element: <ICT />
        },
        {
            path: '/dpm-office',
            element: <DPM />
        },
        {
            path: '/sports-culture-youth',
            element: <SportsCultureAndYouth />
        },
        {
            path: '/defence',
            element: <Defence />
        },
        {
            path: '/gallery',
            element: <Gallery />
        },
        {
            path: '/contracts',
            element: <Contracts />
        },
        {
            path: '/eswatini-fire-department',
            element: <EswatiniFireDepartment />
        },
        {
            path: '/eswatini-parliament',
            element: <EswatiniParliament />
        },
        {
            path: '/correctional-service',
            element: <CorrectionalService />
        },
        {
            path: '/royal-eswatini-police',
            element: <Toilets />
        },
        {
            path: '/health',
            element: <Health />
        },
        {
            path: '/rdf-education',
            element: <Education />
        },
        {
            path: '/rdf-agriculture',
            element: <RdfAgriculture />
        },
        {
            path: '/rdf-rural-electrification',
            element: <RuralElectrification />
        },
        {
            path: '/rdf-water',
            element: <WaterProjects />
        },
        {
            path: '/rdf-community-centres',
            element: <CommunityCentres />
        },
        {
            path: '/rdf-income-generating',
            element: <IncomeGenerating />
        },
        {
            path: 'admin/project',
            element: <Project />
        },
        {
            path: 'admin/new_contract',
            element: <NewContract />
        },
        {
            path: 'admin/edit_contract/:id',
            element: <NewContract />
        },
        {
            path: 'admin/project/:id',
            element: <Project />
        },
        {
            path: '/funds',
            element: <Funds />
        },
        {
            path: '/fund/:id',
            element: <ManageFunds />
        },
        {
            path: '/sectors',
            element: <Sectors />
        },
        {
            path: '/sector/:id',
            element: <ManageSectors />
        },
        {
            path: '/new_sector',
            element: <ManageSectors />
        },
        {
            path: '/new_fund',
            element: <ManageFunds />
        },
        {
            path: '/projects',
            element: <Projects />
        },
        {
            path: '/users',
            element: <Users />
        },
        {
            path: '/unauthorized',
            element: <NotAuthorized />
        },
        {
            path: '/account_settings',
            element: <AccountSettings />
        },
        {
            path: '/reports',
            element: <Reports />
        },
        {
            path: '/health_welfare',
            element: <HealthWelfare />
        }
    ]
};

export default MainRoutes;
