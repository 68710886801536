// assets
import { IconBuildingBridge, IconBuildingCommunity, IconTractor, IconBucket, IconPlug, IconSchool } from '@tabler/icons-react';

// constant
const icons = {
    IconBuildingBridge,
    IconBuildingCommunity,
    IconTractor,
    IconBucket,
    IconPlug,
    IconSchool
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const cdsf = {
    id: 'cdsf',
    title: 'CDSF',
    caption: 'Community Development Special Fund',
    type: 'group',
    children: [
        {
            id: 'dashboard-cdsf',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/cdsf',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'transport',
            title: 'Transport & Communication',
            type: 'collapse',
            icon: icons.IconBuildingBridge,

            children: [
                {
                    id: 'lowLevelCrossing',
                    title: 'Low Level Crossings',
                    type: 'item',
                    url: '/bridges/low-level-crossing',
                    target: false
                },
                {
                    id: 'footbridges',
                    title: 'Foot Bridges',
                    type: 'item',
                    url: '/bridges/foot-bridges',
                    target: false
                },
                {
                    id: 'roads',
                    title: 'Roads',
                    type: 'item',
                    url: '/roads',
                    target: false
                },
                {
                    id: 'tebetebe',
                    title: 'Suspended Foot Bridges',
                    type: 'item',
                    url: '/suspended',
                    target: false
                }
            ]
        },
        {
            id: 'communityCentres',
            title: 'Community Centres',
            type: 'collapse',
            icon: icons.IconBuildingCommunity,

            children: [
                {
                    id: 'halls',
                    title: 'Community Halls',
                    type: 'item',
                    url: '/community-halls',
                    target: false
                },
                {
                    id: 'marketStalls',
                    title: 'Market Stalls',
                    type: 'item',
                    url: '/market-stalls',
                    target: false
                }
            ]
        },
        {
            id: 'agriculture',
            title: 'Agriculture',
            type: 'collapse',
            icon: icons.IconTractor,

            children: [
                {
                    id: 'dipTanks',
                    title: 'Dip-Tanks',
                    type: 'item',
                    url: '/dip-tanks',
                    target: false
                },
                {
                    id: 'veterinary',
                    title: 'Veterinary Offices',
                    type: 'item',
                    url: '/veterinary-offices',
                    target: false
                },
                {
                    id: 'grazing',
                    title: 'Grazing Schemes',
                    type: 'item',
                    url: '/grazing-schemes',
                    target: false
                }
            ]
        },
        {
            id: 'water',
            title: 'Water & Sanitation',
            type: 'collapse',
            icon: icons.IconBucket,

            children: [
                {
                    id: 'communityWater',
                    title: 'Community Water Supply Projects',
                    type: 'item',
                    url: '/community-water',
                    target: false
                },
                {
                    id: 'schoolWater',
                    title: 'School Water Supply Projects',
                    type: 'item',
                    url: '/schools-water',
                    target: false
                },
                {
                    id: 'healthWater',
                    title: 'Health Water Supply Projects',
                    type: 'item',
                    url: '/health-water',
                    target: false
                }
            ]
        },
        {
            id: 'ruralElectrification',
            title: 'Rural Electrification',
            type: 'collapse',
            icon: icons.IconPlug,

            children: [
                {
                    id: 'phase1',
                    title: 'Phase 1',
                    type: 'item',
                    url: '/phase-1',
                    target: false
                },
                {
                    id: 'phase2',
                    title: 'Phase 2',
                    type: 'item',
                    url: '/phase-2',
                    target: false
                },
                {
                    id: 'phase3',
                    title: 'Phase 3',
                    type: 'item',
                    url: '/phase-3',
                    target: false
                },
                {
                    id: 'phase4',
                    title: 'Phase 4',
                    type: 'item',
                    url: '/phase-4',
                    target: false
                },
                {
                    id: 'phase5',
                    title: 'Phase 5',
                    type: 'item',
                    url: '/phase-5',
                    target: false
                }
            ]
        },
        {
            id: 'education',
            title: 'Education',
            type: 'collapse',
            icon: icons.IconSchool,

            children: [
                {
                    id: 'education-hhohho',
                    title: 'Hhohho',
                    type: 'item',
                    url: '/hhohho',
                    target: false
                },
                {
                    id: 'education-manzini',
                    title: 'Manzini',
                    type: 'item',
                    url: '/manzini',
                    target: false
                },
                {
                    id: 'education-lubombo',
                    title: 'Lubombo',
                    type: 'item',
                    url: '/lubombo',
                    target: false
                },
                {
                    id: 'education-shiselweni',
                    title: 'Shiselweni',
                    type: 'item',
                    url: '/shiselweni',
                    target: false
                }
            ]
        },
        {
            id: 'welfare',
            title: 'Health and Welfare',
            type: 'item',                   
            url: '/health_welfare',
            target: false
        }
    ]
};

export default cdsf;
