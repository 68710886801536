// assets
import { IconBuildingFortress, IconBuildingBank } from '@tabler/icons-react';
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';

// constant
const icons = {
    IconBuildingFortress,
    LocalPoliceOutlinedIcon,
    IconBuildingBank
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const cp = {
    id: 'cp',
    title: 'CP',
    caption: 'Capital Projects',
    type: 'group',
    children: [
        {
            id: 'dashboard-cf',
            title: 'Dashboard',
            type: 'item',
            url: '/client/dashboard/cf',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'ministries',
            title: 'Various Other Ministries',
            type: 'collapse',
            icon: icons.IconBuildingBank,

            children: [
                {
                    id: 'health',
                    title: 'Health',
                    type: 'item',
                    url: '/health',
                    target: false
                },
                {
                    id: 'agriculture',
                    title: 'Agriculture',
                    type: 'item',
                    url: '/client/agriculture',
                    target: false
                },
                {
                    id: 'correctional',
                    title: 'Correctional Services',
                    type: 'item',
                    url: '/client/correctional-service',
                    target: false
                },
                {
                    id: 'sports',
                    title: 'Sports, Culture & Youth',
                    type: 'item',
                    url: '/client/sports-culture-youth',
                    target: false
                },
                {
                    id: 'finance',
                    title: 'Finance',
                    type: 'item',
                    url: '/client/finance',
                    target: false
                },
                {
                    id: 'defence',
                    title: 'Defence',
                    type: 'item',
                    url: '/client/defence',
                    target: false
                },
                {
                    id: 'dpm-office',
                    title: "DPM's Office",
                    type: 'item',
                    url: '/client/dpm-office',
                    target: false
                },
                {
                    id: 'ict',
                    title: 'ICT',
                    type: 'item',
                    url: '/client/ict',
                    target: false
                },
                {
                    id: 'royal-eswatini-police',
                    title: 'Royal Eswatini Police',
                    type: 'item',
                    url: '/client/royal-eswatini-police',
                    target: false
                },
                {
                    id: 'eswatini-fire',
                    title: 'Eswatini Fire Department',
                    type: 'item',
                    url: '/client/eswatini-fire-department',
                    target: false
                },
                {
                    id: 'eswatini-fire',
                    title: 'Eswatini Parliament',
                    type: 'item',
                    url: '/client/eswatini-parliament',
                    target: false
                },
                {
                    id: 'private-cabinet',
                    title: 'Private Cabinet (Prime Minister)',
                    type: 'item',
                    url: '/client/private_cabinet',
                    target: false
                }
            ]
        }
    ]
};

export default cp;
