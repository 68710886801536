import { initializeApp, getApp } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';

const InitializeApp = () => {
    try {
        return getApp();
    } catch (any) {
        const firebaseConfig = {
            apiKey: 'AIzaSyCskD95RcKihl9w9G96_YTklGevQ31tH6w',
            authDomain: 'microprojects-430e2.firebaseapp.com',
            projectId: 'microprojects-430e2',
            storageBucket: 'microprojects-430e2.appspot.com',
            messagingSenderId: '882274844253',
            appId: '1:882274844253:web:32c7eb7b6343c4dc1117b4',
            measurementId: 'G-PRWXFN8TLG'
        };
        return initializeApp(firebaseConfig);
    }
};

// Initialize Firebase
const firebaseApp = InitializeApp();
export const messaging = getMessaging(firebaseApp);

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log(payload);
            resolve(payload);
        });
    });
