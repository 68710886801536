import { Alert, AlertTitle } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useStateContext } from 'context/ContextProvider';

const AlertMessage = () => {
    const { open, notification } = useStateContext();
    const state = {
        vertical: 'top',
        horizontal: 'center'
    };

    return notification ? (
        <Snackbar
            anchorOrigin={state}
            autoHideDuration={6000}
            open={open}
            message="Status"
            key={state}
            sx={{ width: '40%', alignItems: 'center' }}
        >
            <Alert severity={notification.severity !== null ? notification.severity : ''} sx={{ width: '100%' }}>
                <AlertTitle>{notification.title}</AlertTitle>
                {notification.message}
            </Alert>
        </Snackbar>
    ) : null;
};

export default AlertMessage;
