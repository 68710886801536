import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import GeneralRoutesProtect from './GeneralRoutesProtect';
import ClientLayout from 'layout/ClientLayout';
import { Navigate } from 'react-router-dom';


const WaterProjects=Loadable(lazy(() =>import('views/pages/rdf/infrastructure-projects/WaterProjects')));
const  PrivateCabinet=Loadable(lazy(()=>import('views/pages/cp/various-other-ministries/PrivateCabinet')));
const Gallery = Loadable(lazy(() => import('views/pages/dashbord/utilities/Gallery')));
const Contractors = Loadable(lazy(() => import('views/pages/reports/database/Contractors')));
const Suppliers = Loadable(lazy(() => import('views/pages/reports/database/Suppliers')));
const Reports = Loadable(lazy(() => import('views/pages/dashbord/utilities/Reports')));
const Contracts = Loadable(lazy(() => import('views/pages/dashbord/utilities/Contracts')));
const HealthWelfare=Loadable(lazy(()=>import('views/pages/cdsf/HealtWelfare')));

const Roads = Loadable(lazy(() => import('views/pages/cdsf/transport&comunication/roads')));
const Suspended = Loadable(lazy(() => import('views/pages/cdsf/transport&comunication/suspended-foot-bridge')));
const CommunityHalls = Loadable(lazy(() => import('views/pages/cdsf/community_centers/CommunityHalls')));
const MarketStalls = Loadable(lazy(() => import('views/pages/cdsf/community_centers/MarketStalls')));

const DipTanks = Loadable(lazy(() => import('views/pages/cdsf/agriculture/DipTanks')));
const GrazingSchemes = Loadable(lazy(() => import('views/pages/cdsf/agriculture/GrazingSchemes')));
const VeterinaryOffices = Loadable(lazy(() => import('views/pages/cdsf/agriculture/VeterinaryOffices')));

const CommunityWaterSupply = Loadable(lazy(() => import('views/pages/cdsf/water&sanitation/CommunityWaterSupply')));
const HealthWaterSupply = Loadable(lazy(() => import('views/pages/cdsf/water&sanitation/HealthWaterSupply')));
const SchoolsWaterSupply = Loadable(lazy(() => import('views/pages/cdsf/water&sanitation/SchoolsWaterSupply')));

const Phase1 = Loadable(lazy(() => import('views/pages/cdsf/rural-electrification/Phase1')));
const Phase2 = Loadable(lazy(() => import('views/pages/cdsf/rural-electrification/Phase2')));
const Phase3 = Loadable(lazy(() => import('views/pages/cdsf/rural-electrification/Phase3')));
const Phase4 = Loadable(lazy(() => import('views/pages/cdsf/rural-electrification/Phase4')));
const Phase5 = Loadable(lazy(() => import('views/pages/cdsf/rural-electrification/Phase5')));

const Hhohho = Loadable(lazy(() => import('views/pages/cdsf/education-hhohho/Hhohho')));
const Lubombo = Loadable(lazy(() => import('views/pages/cdsf/education-hhohho/Lubombo')));
const Manzini = Loadable(lazy(() => import('views/pages/cdsf/education-hhohho/Manzini')));
const Shiselweni = Loadable(lazy(() => import('views/pages/cdsf/education-hhohho/Shiselweni')));

const AdminBlocks = Loadable(lazy(() => import('views/pages/education/AdminBlocks')));
const CarpentryFittings = Loadable(lazy(() => import('views/pages/education/CarpentryFittings')));
const ClassRooms = Loadable(lazy(() => import('views/pages/education/ClassRooms')));
const Fencing = Loadable(lazy(() => import('views/pages/education/Fencing')));
const Kitchens = Loadable(lazy(() => import('views/pages/education/Kitchens')));
const Laboratories = Loadable(lazy(() => import('views/pages/education/Laboratories')));
const Libraries = Loadable(lazy(() => import('views/pages/education/Libraries')));
const StaffHouses = Loadable(lazy(() => import('views/pages/education/StaffHouses')));
const StormDamageAndRehabilitation = Loadable(lazy(() => import('views/pages/education/StormDamageAndRehabilitation')));
const TertiaryInstitution = Loadable(lazy(() => import('views/pages/education/TertiaryInstitution')));
const Toilets = Loadable(lazy(() => import('views/pages/education/Toilets')));

const IncomeGenerating = Loadable(lazy(() => import('views/pages/rdf/income-generating-projects')));
const Project = Loadable(lazy(() => import('ui-component/project')));
const RuralElectrification = Loadable(lazy(() => import('views/pages/rdf/infrastructure-projects/RuralElectrification')));
const RdfAgriculture = Loadable(lazy(() => import('views/pages/rdf/infrastructure-projects/RdfAgriculture')));
const Education = Loadable(lazy(() => import('views/pages/rdf/infrastructure-projects/Education')));
const CommunityCentres = Loadable(lazy(() => import('views/pages/rdf/infrastructure-projects/CommunityCentres')));

const SportsCultureAndYouth = Loadable(lazy(() => import('views/pages/cp/various-other-ministries/SportsCultureAndYouth')));
const ICT = Loadable(lazy(() => import('views/pages/cp/various-other-ministries/ICT')));
const Health = Loadable(lazy(() => import('views/pages/cp/various-other-ministries/Health')));
const Finance = Loadable(lazy(() => import('views/pages/cp/various-other-ministries/Finance')));
const EswatiniParliament = Loadable(lazy(() => import('views/pages/cp/various-other-ministries/EswatiniParliament')));
const EswatiniFireDepartment = Loadable(lazy(() => import('views/pages/cp/various-other-ministries/EswatiniFireDepartment')));
const DPM = Loadable(lazy(() => import('views/pages/cp/various-other-ministries/DPM')));
const Defence = Loadable(lazy(() => import('views/pages/cp/various-other-ministries/Defence')));
const CorrectionalService = Loadable(lazy(() => import('views/pages/cp/various-other-ministries/CorrectionalService')));
const Agriculture = Loadable(lazy(() => import('views/pages/cp/various-other-ministries/EswatiniFireDepartment')));

const AccountSettings = Loadable(lazy(() => import('ui-component/account-settings')));

// dashboard routing
const DashboardCDSF = Loadable(lazy(() => import('views/dashboard/Default/indexCDSF')));
const DashboardMOET = Loadable(lazy(() => import('views/dashboard/Default/indexMOET')));
const DashboardCF = Loadable(lazy(() => import('views/dashboard/Default/indexCF')));
const DashboardRDF = Loadable(lazy(() => import('views/dashboard/Default/indexRDF')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const NotAuthorized = Loadable(lazy(() => import('views/pages/NotAuthorized')));
const UnderConstruction = Loadable(lazy(() => import('views/pages/under-construction')));
const FootBridge = Loadable(lazy(() => import('views/pages/cdsf/transport&comunication/foot-bridge')));
const LowLevelCrossing = Loadable(lazy(() => import('views/pages/cdsf/transport&comunication/low-level-crossing')));

// ==============================|| MAIN ROUTING ||============================== //

const ClientRoutes = {
    path: '/',
    element: (
        <GeneralRoutesProtect>
            <ClientLayout />
        </GeneralRoutesProtect>
    ),
    children: [
        {
            path: '/',
            element: <Navigate to="/client/dashboard/cdsf" replace />
        },
        {
            path: '/client/dashboard/cdsf',
            element: <DashboardCDSF />
        },
        {
            path: '/client/dashboard/moet',
            element: <DashboardMOET />
        },
        {
            path: '/client/dashboard/cf',
            element: <DashboardCF />
        },
        {
            path: '/client/dashboard/rdf',
            element: <DashboardRDF />
        },

        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/client/bridges/foot-bridges',
            element: <FootBridge />
        },
        {
            path: '/client/bridges/low-level-crossing',
            element: <LowLevelCrossing />
        },
        {
            path: '/client/roads',
            element: <Roads />
        },
        {
            path: '/client/suspended',
            element: <Suspended />
        },
        {
            path: '/client/under-construction',
            element: <UnderConstruction />
        },
        {
            path: '/client/community-halls',
            element: <CommunityHalls />
        },
        {
            path: '/client/market-stalls',
            element: <MarketStalls />
        },
        {
            path: '/client/dip-tanks',
            element: <DipTanks />
        },
        {
            path: '/client/veterinary-offices',
            element: <VeterinaryOffices />
        },
        {
            path: '/client/grazing-schemes',
            element: <GrazingSchemes />
        },
        {
            path: '/client/schools-water',
            element: <SchoolsWaterSupply />
        },
        {
            path: '/client/community-water',
            element: <CommunityWaterSupply />
        },
        {
            path: '/client/health-water',
            element: <HealthWaterSupply />
        },
        {
            path: '/client/phase-1',
            element: <Phase1 />
        },
        {
            path: '/client/phase-2',
            element: <Phase2 />
        },
        {
            path: '/client/phase-3',
            element: <Phase3 />
        },
        {
            path: '/client/phase-4',
            element: <Phase4 />
        },
        {
            path: '/client/phase-5',
            element: <Phase5 />
        },
        {
            path: '/client/hhohho',
            element: <Hhohho />
        },
        {
            path: '/client/manzini',
            element: <Manzini />
        },
        {
            path: '/client/shiselweni',
            element: <Shiselweni />
        },
        {
            path: '/client/lubombo',
            element: <Lubombo />
        },
        {
            path: '/client/fencing',
            element: <Fencing />
        },
        {
            path: '/client/libraries',
            element: <Libraries />
        },
        {
            path: '/client/laboratories',
            element: <Laboratories />
        },
        {
            path: '/client/kitchens',
            element: <Kitchens />
        },
        {
            path: '/client/storm-damage-and-rehabilitation',
            element: <StormDamageAndRehabilitation />
        },
        {
            path: '/client/carpentry-fittings',
            element: <CarpentryFittings />
        },
        {
            path: '/client/classrooms',
            element: <ClassRooms />
        },
        {
            path: '/client/staff-houses',
            element: <StaffHouses />
        },
        {
            path: '/client/admin-blocks',
            element: <AdminBlocks />
        },
        {
            path: '/client/tertiary-institution',
            element: <TertiaryInstitution />
        },
        {
            path: '/client/toilets',
            element: <Toilets />
        },
        {
            path: '/client/agriculture',
            element: <Agriculture />
        },
        {
            path: '/client/finance',
            element: <Finance />
        },
        {
            path: '/client/ict',
            element: <ICT />
        },
        {
            path: '/client/dpm-office',
            element: <DPM />
        },
        {
            path: '/client/sports-culture-youth',
            element: <SportsCultureAndYouth />
        },
        {
            path: '/client/defence',
            element: <Defence />
        },
        {
            path: '/client/eswatini-fire-department',
            element: <EswatiniFireDepartment />
        },
        {
            path: '/client/eswatini-parliament',
            element: <EswatiniParliament />
        },
        {
            path: '/client/correctional-service',
            element: <CorrectionalService />
        },
        {
            path: '/client/private_cabinet',
            element: <PrivateCabinet />
        },
        {
            path: '/client/royal-eswatini-police',
            element: <Toilets />
        },
        {
            path: '/client/health',
            element: <Health />
        },
        {
            path: '/client/rdf-education',
            element: <Education />
        },
        {
            path: '/client/rdf-water',
            element: <WaterProjects />
        },
        {
            path: '/client/rdf-agriculture',
            element: <RdfAgriculture />
        },
        {
            path: '/client/rdf-rural-electrification',
            element: <RuralElectrification />
        },
        {
            path: '/client/rdf-community-centres',
            element: <CommunityCentres />
        },
        {
            path: '/client/rdf-income-generating',
            element: <IncomeGenerating />
        },
        {
            path: '/project',
            element: <Project />
        },
        {
            path: '/project/:id',
            element: <Project />
        },
        {
            path: '/unauthorized',
            element: <NotAuthorized />
        },
        {
            path: '/client/account_settings',
            element: <AccountSettings />
        },
        {
            path: '/client/gallery',
            element: <Gallery />
        },
        {
            path: '/client/contractors',
            element: <Contractors />
        },
        {
            path: '/client/suppliers',
            element: <Suppliers />
        },
        {
            path: '/client/reports',
            element: <Reports />
        },
        {
            path: '/client/contracts',
            element: <Contracts />
        },
        {
            path: '/client/health_welfare',
            element: <HealthWelfare />
        }
    ]
};

export default ClientRoutes;
