import config from 'config';
import { createContext, useContext, useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { cleanup } from '@testing-library/react';
import axios from 'axios';

const StateContext = createContext({
    user: null,
    userDetails: null,
    token: null,
    notification: null,
    setUser: () => {},
    setToken: () => {},
    setNotification: () => {},
    open: false,
    setOpen: () => {},
    setUserDetails: () => {},
    MenuOpen: [], // for active default menu
    setMenuOpen: () => {},
    menu: true,
    setMenu: () => {},
    fontFamily: config.fontFamily,
    setFontFamily: () => {},
    borderRadius: config.borderRadius,
    setBorderRadius: () => {},
    getUser: () => {}
});

export const ContextProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [token, _setToken] = useState(localStorage.getItem('ACCESS_TOKEN'));
    const [notification, _setNotification] = useState({});
    const [open, setOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState([]);
    const [menu, setMenu] = useState(true);
    const [fontFamily, setFontFamily] = useState(config.fontFamily);
    const [borderRadius, setBorderRadius] = useState(config.borderRadius);

    const navigate = useNavigate();

    const setToken = (_token) => {
        if (_token) {
            localStorage.setItem('ACCESS_TOKEN', _token);
            _setToken(_token);
        } else {
            _setToken(null);
            localStorage.removeItem('ACCESS_TOKEN');
        }
    };

    const setNotification = (severity, title, message) => {
        setOpen(true);
        _setNotification({
            severity,
            title,
            message
        });

        setTimeout(() => {
            _setNotification(null);
            setOpen(false);
        }, 5000);
    };
    const admins = new Set(['super_admin', 'system_admin']);
    const mpp = new Set(['super_admin', 'system_admin', 'project_manager', 'technical_support']);

    const client = axios.create({
        baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        withCredentials: true
    });
    client.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    });

    const fileApi = axios.create({
        baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json'
        },
        withCredentials: true
    });
    fileApi.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    });

    const getUser = async () => {
        await client
            .get('/user')
            .then((res) => {
                if (res.status === 200) {
                    setUser(res.data);
                    if (admins.has(res.data.type)) {
                        navigate('/dashboard/default');
                    } else if (mpp.has(res.data.type)) {
                        navigate('/client/dashboard/cdsf');
                    } else {
                        navigate('/welcome');
                    }
                }
                if (res.status === 401) {
                    localStorage.removeItem('ACCESS_TOKEN');
                    navigate('/pages/login/login3');
                }
            })
            .catch((err) => {
                if (err && err.response.status === 401) {
                    localStorage.removeItem('ACCESS_TOKEN');
                    navigate('/pages/login/login3');
                }
                console.log(err);
            });
    };

    useEffect(() => {
        if (token !== null) {
            getUser();
        } else {
            navigate('/pages/login/login3');
        }
        return () => {
            cleanup();
        };
    }, [token]);

    const MENU_OPEN = (id) => setMenuOpen([id]);

    const SET_MENU = (state) => setMenu(state);

    const SET_FONT_FAMILY = (f) => setFontFamily(f);

    const SET_BORDER_RADIUS = (r) => setBorderRadius(r);
    const values = useMemo(
        () => ({
            user,
            setUser,
            getUser,
            token,
            setToken,
            client,
            notification,
            setNotification,
            open,
            setOpen,
            menuOpen,
            MENU_OPEN,
            menu,
            SET_MENU,
            fontFamily,
            SET_FONT_FAMILY,
            borderRadius,
            SET_BORDER_RADIUS,
            fileApi
        }),
        [
            user,
            setUser,
            getUser,
            token,
            setToken,
            client,
            notification,
            setNotification,
            open,
            setOpen,
            menuOpen,
            MENU_OPEN,
            menu,
            SET_MENU,
            fontFamily,
            SET_FONT_FAMILY,
            borderRadius,
            SET_BORDER_RADIUS,
            fileApi
        ]
    );
    return <StateContext.Provider value={values}>{children}</StateContext.Provider>;
};

export const useStateContext = () => useContext(StateContext);
