import { useTheme } from '@mui/material/styles';
import { Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import AuthFooter from 'ui-component/cards/AuthFooter';

// assets
import logo from 'assets/images/logo1.png';
import { useStateContext } from 'context/ContextProvider';

const NotAuthorized = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const { user } = useStateContext();

    return (
        <div>
            <Paper>
                <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                            <Grid item xs={6} style={{ textAlign: 'center' }}>
                                <Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h4' : 'h3'}>
                                    Hi..., {`${user.fname} ${user.lname}`} you not allowed to access this page
                                </Typography>
                                <Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h2' : 'h1'}>
                                    Micro Projects eSwatini
                                </Typography>
                                <img src={logo} alt="logo" />
                                <Typography
                                    color={theme.palette.secondary.main}
                                    gutterBottom
                                    variant={matchDownSM ? 'h4' : 'h3'}
                                    style={{ fontStyle: 'italic' }}
                                >
                                    &quot;We Empower Rural Communities To Develop Themselves&quot;
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                        <AuthFooter />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};

export default NotAuthorized;
