import image1 from './backgrounds/1.jpg';
import image2 from './backgrounds/2.jpg';
import image3 from './backgrounds/3.JPG';
import image4 from './backgrounds/4.JPG';
import image5 from './backgrounds/5.png';
import image6 from './backgrounds/6.jpg';
import image7 from './backgrounds/7.JPG';
import image8 from './backgrounds/8.JPG';
import image9 from './backgrounds/9.png';
import image10 from './backgrounds/10.jpg';
import logo from './logo.png';

export const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, logo];
